jQuery(document).ready(function(){
		
    //Accordion Nav
    jQuery('.mainNav').navAccordion({
        expandButtonText: '<i class="fa fa-caret-down"></i>',  //Text inside of buttons can be HTML
        collapseButtonText: '<i class="fa fa-caret-up"></i>'
    }, 
    function(){
        console.log('Callback')
    });
    
});